@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../../assets/filed-lib-theme/variables.scss';
@import 'app';
@import 'variable';
@import 'generic-properties';
@import 'typography';
@import 'responsiveness';
@import 'form';
@import './side-pop-ups.scss';
@import 'standard-colors';

.backdrop-background {
	background-color: rgba(0, 0, 0, 0.5) !important;
	backdrop-filter: blur(3px) !important;
	-webkit-backdrop-filter: blur(3px) !important;
}

.custom-dialog .mat-dialog-container {
	padding: 24px 24px 20px 24px !important;
	border-radius: 5px !important;
}

@media only screen and (min-width: 425px) and (max-width: 578px) {
	.custom-dialog .mat-dialog-container {
		padding: 23px 23px 23px 35px !important;
		border-radius: 10px !important;
	}
}

@media only screen and (min-width: 315px) and (max-width: 424px) {
	.custom-dialog .mat-dialog-container {
		padding: 20px !important;
		border-radius: 10px !important;
	}
}

body {
	& > * {
		color: $text-color;
	}
}
.mat-row:hover {
	background-color: #f5f5f5; /* Change this to your desired hover background color */
	cursor: pointer; /* Optional: Show pointer cursor on hover */
}
.mat-header-row {
	cursor: pointer;
	position: sticky;
	top: 0;
	background-color: #f5f5f5; /* Change this to your desired header background color */
	z-index: 1;
}
form {
	input,
	textarea,
	select {
		&::-webkit-input-placeholder {
			font-size: 14px !important;
			line-height: 26px;
			font-weight: 500;
			color: rgba(120, 120, 120, 0.7) !important;
		}

		&:-ms-input-placeholder {
			font-size: 14px !important;
			line-height: 26px;
			font-weight: 500;
			color: rgba(120, 120, 120, 0.7) !important;
		}

		&::placeholder {
			font-size: 14px !important;
			line-height: 26px;
			font-weight: 500;
			color: rgba(120, 120, 120, 0.7) !important;
		}
	}

	.form-group-wrapper {
		display: block;
		margin-bottom: 30px;

		&.no-margin {
			margin: 0;
		}

		.label-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12.49px;

			& > div {
				display: inline-flex;
				align-items: center;
			}

			.counter {
				text-align: right;
				font: normal normal 500 12px/14px Gilroy;
				letter-spacing: 0.12px;
				color: #787878;
				opacity: 1;
				min-width: 39px;
				margin-left: 2px;
			}

			label {
				display: block;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
}

.shepherd-elements {
	background: #006bff 0% 0% no-repeat padding-box;
	color: #fff;
	box-shadow: 7px 11px 26px #0146a73d;
	border-radius: 15px;

	&.left {
	}

	max-width: 426px;
	opacity: 0;
	outline: none;
	transition: opacity 0.3s, visibility 0.3s;
	visibility: hidden;
	width: 100%;
	padding: 32px;
	margin: 0 10px !important;

	.shepherd-arrow {
		visibility: hidden;
		display: none !important;
	}

	&.shepherd-has-title {
		.shepherd-content {
			.shepherd-header {
				background: #006bff 0% 0% no-repeat padding-box;
				display: block;
				width: 100%;
				padding: 0;

				h3 {
					text-align: left;
					font: normal normal bold 28px/40px Gilroy;
					letter-spacing: 0px;
					color: #ffffff;
				}
			}

			.shepherd-text {
				text-align: left;
				font: normal normal 500 16px/27px Gilroy;
				letter-spacing: 0px;
				color: #ffffff;
				opacity: 1;
				padding: 0;
				margin-bottom: 16px;
				margin-top: 16px;
			}

			.shepherd-footer {
				display: flex;
				justify-content: space-between;
				padding: 0;

				.shepherd-button-secondary {
					font: normal normal 600 14px/1px Roboto;
					letter-spacing: 0.14px;
					color: #ffffff;
					text-transform: uppercase;
					background: none;
					border: none;
					text-align: left;
					padding: 0;

					img {
						margin-left: 5px;
						height: 11px;
						width: 11px;
					}
				}

				.shepherd-button-primary {
					min-width: 128px;
					height: 49px;
					background: #ffffff 0% 0% no-repeat padding-box;
					border-radius: 5px;
					text-align: center;
					font: normal normal bold 16px/26px Gilroy;
					letter-spacing: 0.16px;
					color: #006bff;

					img {
						margin-left: 8px;
						height: 11px;
						width: 15px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: 900px) {
		margin: 10px 10px !important;
		width: 90%;
		max-width: calc(100% - 20px);

		&.static {
			transform: translate(0, -64px) !important;
		}

		&.shepherd-has-title {
			.shepherd-content {
				.shepherd-header {
					background: #006bff 0% 0% no-repeat padding-box;
					display: block;
					width: 100%;
					padding: 0;

					h3 {
						text-align: left;
						font: normal normal bold 24px/32px Gilroy;
						letter-spacing: 0px;
						color: #ffffff;
					}
				}

				.shepherd-text {
					text-align: left;
					font: normal normal 500 14px/23px Gilroy;
					letter-spacing: 0px;
					color: #ffffff;
					opacity: 1;
					padding: 0;
					margin-bottom: 16px;
					margin-top: 16px;
				}

				.shepherd-footer {
					display: flex;
					justify-content: space-between;
					padding: 0;

					.shepherd-button-secondary {
					}

					.shepherd-button-primary {
					}
				}
			}
		}
	}
}

::ng-deep {
	.date-selector-wrapper {
		kendo-datepicker {
			.k-datepicker {
				border: 2px solid #ebeff5 !important;
				border-radius: 5px !important;
				flex-direction: row-reverse;

				.k-input-inner {
					border: none;
				}

				.k-button {
					height: 100%;
					width: auto;
					border: none;
				}

				&.k-focus {
					border-color: $primary-color;
					box-shadow: 0 0 0 5px $primary-color-40;
				}
			}

			.k-datepicker .k-select {
				color: #006bff;
				background-color: white !important;
				border: none !important;
			}

			.k-select {
				padding: 0px 12px !important;
			}

			.k-input {
				padding: 0 !important;
				color: #121212 !important;
				font-size: 14px !important;
				font-weight: 500;
			}
		}
	}
}

::ng-deep .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
	background-color: #146cfc !important;
	color: #fff;
}

.img-selector-segment {
	display: flex;
	width: 100%;
	margin-bottom: 30px;

	button {
		height: 48px;
		width: 390px;
		background: #eeeeee 0% 0% no-repeat padding-box;
		border-radius: 8px;
		border: none;
		font: normal normal 500 14px/16px Gilroy;

		&.selected {
			background: $primary-color 0% 0% no-repeat padding-box;
			z-index: 1;
			color: #fff;
		}

		&:nth-child(even) {
			margin-left: -8px;
		}
	}
}

.w-100 {
	width: 100%;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.tool-group {
	margin-bottom: 50px;

	h6 {
		font: normal normal bold 18px/16px Gilroy;
		letter-spacing: 0px;
		color: #121212;
		margin-bottom: 19px;
		display: flex;
		justify-content: space-between;

		a {
			font: normal normal 600 16px/16px Gilroy;
			letter-spacing: 0px;
			color: $primary-color;
		}
	}
}

::ng-deep {
	.control-input-group {
		.ngx-slider .ngx-slider-pointer {
			background-color: $primary-color;
		}

		.ngx-slider .ngx-slider-selection {
			background-color: $primary-color;
		}
	}
}
::ng-deep {
	.tooltip-inner {
		// background-color: #ffff;
		font-size: 80% !important;
		text-align: left !important;
		// color: black;
		box-shadow: 0px 0px 40px lightgray !important;
		padding: 15px 15px !important;
		font-weight: 500 !important;
	}
}
