/////// Width classes /////////

// Uses examples:

// width: w-1rem, w-1px, w-1p
// max-width: max-w-1rem, max-w-1px, max-w-1p
// min-width: min-w-1rem, min-w-1px, min-w-1p

$spaceamounts: (
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	21,
	22,
	23,
	24,
	25,
	26,
	27,
	28,
	29,
	30,
	31,
	32,
	33,
	34,
	35,
	36,
	37,
	38,
	39,
	40,
	41,
	42,
	43,
	44,
	45,
	46,
	47,
	48,
	49,
	50,
	51,
	52,
	53,
	54,
	55,
	56,
	57,
	58,
	59,
	60,
	61,
	62,
	63,
	64,
	65,
	66,
	67,
	68,
	69,
	70,
	71,
	72,
	73,
	74,
	75,
	76,
	77,
	78,
	79,
	80,
	81,
	82,
	83,
	84,
	85,
	86,
	87,
	88,
	89,
	90,
	91,
	92,
	93,
	94,
	95,
	96,
	97,
	98,
	99,
	100,
	150,
	183,
	186,
	200,
	250,
	300,
	350,
	360,
	400,
	450,
	500,
	800
);

$px: 'px';
$rem: 'rem';
$percentage: '%';
$p: 'p';

@each $space in $spaceamounts {
	.w-#{$space}#{$rem} {
		width: #{$space}#{$rem} !important;
	}

	.w-#{$space}#{$px} {
		width: #{$space}#{$px} !important;
	}

	.w-#{$space}#{$p} {
		width: #{$space}#{$percentage} !important;
	}

	.max-w-#{$space}#{$rem} {
		max-width: #{$space}#{$rem} !important;
	}

	.max-w-#{$space}#{$px} {
		max-width: #{$space}#{$px} !important;
	}

	.max-w-#{$space}#{$p} {
		max-width: #{$space}#{$percentage} !important;
	}

	.min-w-#{$space}#{$rem} {
		min-width: #{$space}#{$rem} !important;
	}

	.min-w-#{$space}#{$px} {
		min-width: #{$space}#{$px} !important;
	}

	.min-w-#{$space}#{$p} {
		min-width: #{$space}#{$percentage} !important;
	}
}
