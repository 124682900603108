$primary-color: #006bff;
$primary-color-80: #2585fa;
$primary-color-60: #7db3ff;
$primary-color-40: #a7ccff;
$primary-color-tint: #e8f2ff;

$danger: #fb1847;
$danger-80: #fc5c7d;
$danger-60: #fd8ba2;
$danger-40: #feb9c7;
$danger-tint: #ffe7ec;
$text-color: #121212;
$white: #ffffff;
