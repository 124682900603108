@import './responsiveness.scss';
// Creating 12 grid system very similar to bootstrap.

$extra-small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$extra-large-screen: 1200px;

.row {
	display: flex;
	flex-wrap: wrap;
}

$columns: 12;

@for $i from 1 through $columns {
	// For Extra Small Screen ( < 576 px)
	@include media-below($extra-small-screen) {
		.grid-col-#{$i} {
			flex: 0 0 calc(100% / $columns * $i);
		}
	}

	// For Extra Small Screen ( >= 576 px)
	@include media-above($extra-small-screen) {
		.grid-col-sm-#{$i} {
			flex: 0 0 calc(100% / $columns * $i);
		}
	}

	// For Extra Small Screen ( >= 768 px)
	@include media-above($medium-screen) {
		.grid-col-md-#{$i} {
			flex: 0 0 calc(100% / $columns * $i);
		}
	}

	// For Extra Small Screen ( >= 992 px)
	@include media-above($large-screen) {
		.grid-col-lg-#{$i} {
			flex: 0 0 calc(100% / $columns * $i);
		}
	}

	// For Extra Small Screen ( >= 1200 px)
	@include media-above($extra-large-screen) {
		.grid-col-xl-#{$i} {
			flex: 0 0 calc(100% / $columns * $i);
		}
	}

	// offset example
	.col-offset-#{$i} {
		margin-left: 0 0 calc(100% / $columns * $i);
	}
}
