@import '../../../src/assets/styles/variable';

.mat-form-field-appearance-outline {
	&.mat-focused {
		.mat-form-field-outline-thick {
			// color: $light-blue;
			color: $filed-blue;
		}
	}

	.mat-form-field-outline-thick {
		color: $filed-blue;
	}

	.mat-form-field-subscript-wrapper {
		padding: 0 !important;
	}
}
