@import '@angular/material/theming';
@import 'standard-colour-codes';
@import 'standard-colors';
/************************************** FONT DEFINITION **************************************/

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 400;
	src: url(../gilroy-fonts/Shakespeare-Regular.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 400;
	src: url(../gilroy-fonts/Shakespeare-Regular.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 500;
	src: url(../gilroy-fonts/Shakespeare-Medium.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 500;
	src: url(../gilroy-fonts/Shakespeare-Medium.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 600;
	src: url(../gilroy-fonts/Shakespeare-SemiBold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 600;
	src: url(../gilroy-fonts/Shakespeare-SemiBold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 700;
	src: url(../gilroy-fonts/Shakespeare-Bold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 700;
	src: url(../gilroy-fonts/Shakespeare-Bold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 800;
	src: url(../gilroy-fonts/Shakespeare-ExtraBold.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 800;
	src: url(../gilroy-fonts/Shakespeare-ExtraBold.woff) format('woff');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 900;
	src: url(../gilroy-fonts/Shakespeare-Black.woff2) format('woff2');
}

@font-face {
	font-family: Gilroy;
	font-style: normal;
	font-weight: 900;
	src: url(../gilroy-fonts/Shakespeare-Black.woff) format('woff');
}

/************************************** body **************************************/

* {
	outline: none;
	box-sizing: border-box;
	font-family: Gilroy, sans-serif;
}

html,
body {
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: Gilroy, sans-serif;
	font-size: 18px;
	line-height: 1.4;
	font-weight: 400;
	-webkit-text-size-adjust: 100%;
	color: $text-color;
	overflow-x: hidden;
	overflow-y: hidden;
	text-align: left;
	min-height: 100%;
	background-color: #ffffff;
}

html {
	position: relative;
	min-height: 100%;
	background-color: $white;
}

/*h1-h6*/

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
h6,
.h6 {
	margin-bottom: 0.5vh;
	margin-top: 0.5vh;
	font-weight: bold;
}

h1,
.h1 {
	font-size: 54px;
	letter-spacing: 1.54px;
	color: $text-color;
}

h2,
.h2 {
	font-size: 38px;
	letter-spacing: 1.4px;
	color: $text-color;
}

h3,
.h3 {
	font-size: 32px;
	letter-spacing: 1.12px;
	color: $text-color;
}

h4,
.h4 {
	font-size: 28px;
	color: $text-color;
	letter-spacing: 0.98px;
}

h5,
.h5 {
	font-size: 24px;
	color: $text-color;
}

h6,
.h6 {
	font-size: 18px;
	color: $text-color;
}

.subtitle-main {
	font-size: 1.5rem;
	font-weight: 500;
	color: $text-color;
	letter-spacing: 0.24px;
}

.subtitle-secondary {
	font-size: 1.38rem;
	font-weight: 500;
	color: $text-color;
	letter-spacing: 0.22px;
}

p,
.p1 {
	margin-bottom: 0.25rem;
	font-size: 18px;
	color: $text-color;
	font-weight: 500;
}
.p2 {
	@extend p;
	font-size: 16px;
	line-height: 25px;
}
.p3 {
	@extend p;
	font-size: 14px;
	line-height: 22px;
}
.p4 {
	@extend p;
	font-size: 12px;
	line-height: 22px;
}

ol,
ul {
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 1.65vh;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

b,
strong {
	font-weight: 600;
}

th {
	font-weight: 500;
	color: $text-color;
	letter-spacing: 0.15px;
	font-size: 14px;
}

label {
	font-weight: 500;
	text-transform: capitalize;
}

small {
	font-size: 12px;
}

/*Links*/

a:hover,
a:focus,
a {
	text-decoration: none;
	background-color: transparent;
	color: $filed-blue;
}

a.link {
	color: $filed-blue;
	text-decoration: none;

	&:hover,
	&:focus {
		cursor: pointer;
		text-decoration: none;
	}
}
.mt-24 {
	margin-top: 24px;
}
